import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullhorn } from '@fortawesome/free-solid-svg-icons'
import Tools from './Tools'
import './Login.css'
import axios from 'axios'
import { Redirect } from 'react-router'

export default class Login extends React.Component {
  state = {
    toMain: false
  }

  login () {
    const user = document.getElementById('user').value
    const pass = document.getElementById('pass').value

    axios({
      method: 'post',
      url: `${Tools.srv}/api/v1/users/login`,
      data: 'user=' + user + '&pass=' + pass,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((res) => {
      // eslint-disable-next-line no-unused-expressions
      res.data.success === true ? Tools.token = res.data.token : Tools.token
      localStorage.setItem('token', Tools.token)
      this.setState({toMain: true})
    })
  }

  componentDidMount () {
    document.body.style.backgroundImage = 'url("background-192112.jpg")'
    document.title = 'AnnoyMe | Login'
    Tools.checkAuth().then(res => {
      Tools.user = res.user
      console.log('Auth: ' + res.valid + ', User: ' + Tools.user)
      if (res.success) {
        this.setState({toMain: true})
      }
    })
  }

  render () {
    if (this.state.toMain === true) {
      return <Redirect to='/' />
    }
    return (
      <div className="Login">
        <header className="Login-header">
          <nav className="navbar navbar-light bg-light">
            <span className="navbar-brand mb-0 h1"><FontAwesomeIcon icon={faBullhorn}/> AnnoyMe | Control</span>
            <form className="form-inline">
              <input aria-label="Change server" className="form-control mr-sm-2" id="srv"
                     placeholder="Put in server address"
                     type="text" defaultValue={Tools.srv}/>
              <button className="btn btn-outline-success my-2 my-sm-0" onClick={Tools.handleSrvChange}
                      type="button">Change
                server
              </button>
              <div style={{ width: 10 }}/>
            </form>
          </nav>
          <div className="login-box">
            <form>
              <h1 className="login-h1">Login</h1>
              <div style={{ height: 20 }}/>
              <label className="login-input label">USERNAME</label>
              <div style={{ height: 5 }}/>
              <input className="login-input" type="text" id="user"/>
              <br/>
              <br/>
              <label className="login-input label">PASSWORD</label>
              <div style={{ height: 5 }}/>
              <input className="login-input" type="password" id="pass"/>
              <br/>
              <div style={{ height: 40 }}/>
              <button type="button" className="login-input submit" id="login" onClick={() => this.login() }>Login</button>
            </form>
          </div>
        </header>
      </div>
    )
  }
}
