import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBullhorn,
  faPlay,
  faPlusSquare,
  faSignOutAlt,
  faStop,
  faTrashAlt,
  faVolumeUp
} from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.css'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import './App.css'
import Tools from './Tools'
import { Redirect } from 'react-router'

const Uppy = require('@uppy/core')
const Dashboard = require('@uppy/dashboard')
const XHRUpload = require('@uppy/xhr-upload')
const jdenticon = require('jdenticon')


// =====================================================================================================================
// NAVBAR COMPONENT
// =====================================================================================================================

class Nav extends React.Component {
  state = {
    server: Tools.srv,
    logout: false
  }

componentDidMount () {
  this.handleSrvChange = () => {
    console.log(document.getElementById('srv').value)
    localStorage.setItem('srv', document.getElementById('srv').value)
    this.setState({ server: Tools.srv })
    //localStorage.removeItem('token')
    window.location.reload() // TODO: do via router
  }

  if (document.getElementById("identicon") !== null) jdenticon.drawIcon(document.getElementById("identicon").getContext("2d"), Tools.user, 38)

  this.logout = () => {
    Tools.api.get('/api/v1/users/logout')
    this.setState({ logout: true })
  }
}

  render () {
    if (this.state.logout) {
      return <Redirect to='/login'/>
    }
    return (
      <nav className="navbar navbar-light bg-light">
        <span className="navbar-brand mb-0 h1"><FontAwesomeIcon icon={faBullhorn}/> AnnoyMe | Control</span>
        <form className="form-inline">
          <input aria-label="Change server" className="form-control mr-sm-2" id="srv"
                 placeholder="Put in server address"
                 type="text" defaultValue={this.state.server}/>
          <button className="btn btn-outline-success my-2 my-sm-0" onClick={this.handleSrvChange}
                  type="button">Change
            server
          </button>
          <div style={{ width: 10 }}/>
          <button type="button" className="btn btn-secondary" id="logout" onClick={() => this.logout()}>
            <FontAwesomeIcon icon={faSignOutAlt}/>
          </button>
          <div style={{ width: 10 }}/>
          <canvas id="identicon" width="38" height="38" data-jdenticon-value={Tools.user}/>
        </form>
      </nav>
    )
  }
}

// =====================================================================================================================
// TABE ROW COMPONENT
// =====================================================================================================================

class SoundsRow extends React.Component {
  render () {
    return (
      <tr key={this.props.id} id={this.props.id}>
        <th scope="row" key={this.props.id}>{this.props.filename}</th>
        <td style={{ textAlign: 'center' }}>
          <input type="radio" className="radio" name="selector" defaultChecked={this.props.selected}
                 onChange={() => Tools.api.put(`/api/v1/sounds/select/${this.props.id}`)
                 }/>
        </td>
        <td style={{ textAlign: 'center' }}>
          <button onClick={() => document.getElementById(this.props.id).querySelector('.player').play()}
                  className={'btn btn-success'}><FontAwesomeIcon icon={faPlay}/>
          </button>
          <audio className="player" src={`${Tools.srv}/api/v1/sounds/download/${this.props.id}`}/>
        </td>
        <td style={{ textAlign: 'center' }}>
          <button onClick={() => Tools.api.get(`/api/v1/sounds/start/${this.props.id}`).catch(SoundsList.errorHandler)}
                  className={'btn btn-info'}><FontAwesomeIcon icon={faPlay}/></button>
        </td>
        <td style={{ textAlign: 'center' }}>
          <button onClick={() => {
            Tools.api.delete(`/api/v1/sounds/${this.props.id}`).catch(SoundsList.errorHandler)
            SoundsList.refresh()
          }}
                  className={'btn btn-danger'}><FontAwesomeIcon icon={faTrashAlt}/></button>
        </td>
      </tr>
    )
  }
}

// =====================================================================================================================
// MAIN TABLE COMPONENT
// =====================================================================================================================

class SoundsTable extends React.Component {
  render () {
    return (
      <table className="table">
        <thead>
        <tr>
          <th scope="col">Name</th>
          <th style={{ textAlign: 'center' }}>Selected</th>
          <th scope="col" style={{ textAlign: 'center' }}><FontAwesomeIcon icon={faPlay}/> Local</th>
          <th scope="col" style={{ textAlign: 'center' }}><FontAwesomeIcon icon={faPlay}/> Remote</th>
          <th scope="col" style={{ textAlign: 'center' }}>Delete Sound</th>
        </tr>
        </thead>
        <tbody>
        {this.props.data.map(item =>
          <SoundsRow key={item.id} id={item.id} selected={item.selected} filename={item.filename}/>
        )}
        </tbody>
      </table>
    )
  }
}

// =====================================================================================================================
// MAIN APP COMPONENT
// =====================================================================================================================

export default class SoundsList extends React.Component {
  state = {
    data: [],
    logout: false
  }

  constructor (props) {
    super(props)
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this)
    this.uppy = Uppy({
      restrictions: {
        allowedFileTypes: ['audio/*']
      },
      autoProceed: false
    })
  }

  static getVolume () {
    return (document.getElementById('volume') === null ? 100 : document.getElementById('volume').value)
  }

  forceUpdateHandler () {
    Tools.api.get(`/api/v1/sounds`)
      .then(res => {
        if (res !== undefined) {
          const data = res.data
          this.setState({ data })
        }
        else {
          this.setState({logout: true})
        }
      })
    jdenticon.update("#identicon")
  }

  componentDidMount () {

    SoundsList.refresh = () => {
      this.forceUpdateHandler()
    }

    SoundsList.errorHandler = (error) => {
      if (error.response) {
        console.log(error.response.status)
        if (error.response.status === 403) {
          let token = ''
          localStorage.setItem('token', token)

          SoundsList.refresh()
        }
      } else if (error.request) {
        console.log(error.request)
      } else {
        console.log('Error', error.message)
      }
    }

    Tools.setAuthorizationToken(localStorage.getItem('token'))

    document.body.style.backgroundImage = 'url(null)' // disable background image from the login page

    Tools.checkAuth().then(res => {
      if (!res.success) {
        this.setState({ logout: true })
      } else {
        Tools.user = res.user
        console.log('Auth: ' + res.success + ', User: ' + Tools.user)
        this.setState({ logout: false })
      }
    })

    Tools.api.get(`/api/v1/sounds`)
      .then(res => {
        const data = res.data
        this.setState({ data })
      })
      .catch(SoundsList.errorHandler)

    // UPPY FILE UPLOAD PART

    this.uppy.use(Dashboard, {
      target: 'body',
      proudlyDisplayPoweredByUppy: false,
      inline: false,
      trigger: '#upload'
    })

    this.uppy.use(XHRUpload, {
      endpoint: `${localStorage.getItem('srv') || 'http://localhost:3000'}/api/v1/sounds`,
      formData: true,
      fieldName: 'soundFile',
      headers: {
        'authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })

    this.uppy.on('complete', () => {
      this.forceUpdateHandler()
    })
  }

  componentWillUnmount () {
    this.uppy.close()
  }

  render () {
    if (this.state.logout) {
      return <Redirect to='/login'/>
    }
    // TODO: only give the important parts to the component
    return (
      <div className="App" style={{ backgroundColor: 'white', backgroundImage: 'null' }}>
        <header className="App-header">
          <Nav username={this.state.data}/>
          <SoundsTable data={this.state.data}/>
        </header>
        <footer>
          <form>
            <div className="form-row" style={{ textAlign: 'right', paddingRight: 10, paddingBottom: 10 }}>
              <div className="col" style={{ paddingLeft: 10 }}>
                <div className="card" style={{ width: '45%', textAlign: 'left', paddingLeft: 10, paddingBottom: 10 }}>
                  <div className="card-body">
                    <h5 className="card-title"><FontAwesomeIcon icon={faVolumeUp}/></h5>
                    <h6 className="card-subtitle mb-2 text-muted">Control the volume <br/>(current: <span
                      id='currentVolume'>{SoundsList.getVolume()}</span>%)</h6>
                    <input className="form-control-range" id="volume" type="range" defaultValue="100"
                           onChange={(e) => {
                             Tools.api.put(`/api/v1/sounds/volume/${e.target.value}`)
                             document.getElementById('currentVolume').innerText = e.target.value
                           }}/>
                  </div>
                </div>
              </div>
              <div className="col" style={{ padding: 20 }}>
                <button className='btn btn-success' id="upload" type="button"><FontAwesomeIcon icon={faPlusSquare}/> Add
                  sound
                </button>
                <button className="btn btn-danger" id="stop" type="button"
                        onClick={() => Tools.api.get('/api/v1/sounds/stop')}>
                  <FontAwesomeIcon icon={faStop}/> Stop sound
                </button>
              </div>
            </div>
          </form>
        </footer>
      </div>
    )
  }
}
