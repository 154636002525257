import axios from 'axios'
import Nav from './App'

export default class Tools {

  static token = localStorage.getItem('token') // deprecated
  static srv = localStorage.getItem('srv') // deprecated

  static user = 'unset'

  static api = axios.create({
    baseURL: Tools.srv,
    responseType: 'json',
  })

  static setAuthorizationToken(token) {
    if(token) {
      axios.defaults.headers.common['authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['authorization'];
    }
  }

  static handleSrvChange () {
    console.log(document.getElementById('srv').value)
    localStorage.setItem('srv', document.getElementById('srv').value)
    Nav.setState('sever', Tools.srv)
    //localStorage.removeItem('token')
    window.location.reload() // TODO: do via router
  }

  static async checkAuth () {
    let state
    const token = localStorage.getItem('token')
    await this.api.post(`${Tools.srv}/api/v1/users/check`, {
      token: token
    }).then(res => {
      if (res.data !== undefined) {
        state = res.data
      } else {
        state = { success: false }
      }
      return state
    })
    return state
  }
}
